import './App.css';
import Home from './Pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Value from './Pages/Value';
import Culture from './Pages/Culture';
import ContactUs from './Pages/ContactUs';
import StoreLocator from './Pages/StoreLocator';
import TermsCondition from './Pages/TermsCondition';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import DeleteAc from './Pages/DeleteAccount';

function App() {
  return (
    <>
      <Router>
        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="/ourvalues" element={<Value />} />
          <Route path="/ourculture" element={<Culture />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/storelocator" element={<StoreLocator />} />
          <Route path="/tnc" element={<TermsCondition />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/delete-account" element={<DeleteAc />} />





          {/* <Route path="*" element={<ErrorPage />} /> */}


        </Routes>
      </Router>

    </>
  );
}

export default App;
