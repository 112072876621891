import * as React from "react";
const RespectImg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        data-name="Layer 1"
        width={256}
        height={276}
        viewBox="0 0 685.51522 739.81459"
        {...props}
    >
        <defs>
            <pattern
                id="e316dee7-4006-4b05-87d0-6679d9899947-180"
                data-name="New Pattern Swatch 2"
                width={40.06228}
                height={88.60733}
                patternTransform="translate(-33.63512 45.49742)"
                patternUnits="userSpaceOnUse"
                viewBox="0 0 40.062 88.607"
            >
                <rect width={40.06228} height={88.60733} fill="none" />
                <path
                    d="M8.42117,12.83873l-.351,9.44982c-.01793.4827.7321.48195.75,0l.351-9.44982c.01793-.48269-.7321-.48195-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M14.47174,14.72892l-.35519,10.58344c-.0162.48275.73382.48215.75,0l.35519-10.58344c.0162-.48274-.73382-.48214-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M22.49338,9.06247V19.2672a.37522.37522,0,0,0,.75,0V9.06247a.37522.37522,0,0,0-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M21.35952,32.49554l-.7559,6.04725c-.05972.47772.69082.47338.75,0l.7559-6.04725c.05972-.47772-.69082-.47338-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M12.33933,31.74523,11.212,41.95068c-.05292.479.69749.47529.75,0l1.12731-10.20545c.05291-.479-.6975-.47528-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M6.24141,32.8735v8.315a.37522.37522,0,0,0,.75,0v-8.315a.37522.37522,0,0,0-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M25.517,14.35381v9.44882a.37522.37522,0,0,0,.75,0V14.35381a.37522.37522,0,0,0-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M25.517,34.38531v8.69291a.37522.37522,0,0,0,.75,0V34.38531a.37522.37522,0,0,0-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M17.26482,24.46206l.269,8.50439c.01523.48145.76529.48357.75,0l-.269-8.50439c-.01523-.48145-.7653-.48356-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M17.769,46.00735l.28347,4.252c.03193.47908.78217.48264.75,0l-.28347-4.252c-.03194-.47907-.78217-.48263-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M12.86987,50.82l-.54861,7.088c-.03725.48131.71293.4789.75,0l.54861-7.088c.03725-.48131-.71293-.4789-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M9.04708,27.95505l.83764,4.25448c.09315.47313.81618.27285.72321-.19938l-.83764-4.25448c-.09315-.47313-.81618-.27284-.72321.19938Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M8.13118,46.85775,7.84771,52.8105c-.023.48249.72708.48131.75,0l.28347-5.95275c.023-.48249-.72708-.48131-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M3.05092,36.93428,4.468,51.10753c.04757.47576.79806.48067.75,0L3.80092,36.93428c-.04757-.47576-.79806-.48067-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M20.65408,32.68363l.28342,16.15748a.37523.37523,0,0,0,.75,0l-.28342-16.15748a.37523.37523,0,0,0-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M29.23645,29.57275l-.85038,17.29134c-.02372.48244.72634.4812.75,0l.85038-17.29134c.02373-.48245-.72633-.4812-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M23.4417,11.14158l-1.70079,15.874c-.05136.47931.699.47569.75,0l1.70079-15.874c.05135-.47932-.699-.47569-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M17.63834.36051,16.51923,16.519c-.03336.48172.71678.47965.75,0L18.38834.36051c.03336-.48172-.71678-.47964-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M14.11036,2.92391,11.08567,28.16345c-.05731.47821.69319.47408.75,0L14.86036,2.92391c.0573-.47822-.69319-.47408-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M6.76452,2.06841l.922,24.09174c.01842.48109.76851.48348.75,0l-.922-24.09174c-.01841-.48109-.7685-.48348-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M11.89378,1.86444l7.922,22.96581c.15645.45356.8812.25865.7232-.19937L12.617,1.66506c-.15645-.45356-.8812-.25865-.72321.19938Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M36.71248,3.20321,36.42908,17.093a.37523.37523,0,0,0,.75,0l.2834-13.88977a.37523.37523,0,0,0-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M26.64758,26.72779l.865,19.84188c.021.48077.77107.48338.75,0l-.865-19.84188c-.021-.48077-.77107-.48338-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M26.55637,55.36168l-.56693,9.07087c-.03012.482.72.48021.75,0l.56693-9.07087c.03013-.482-.72-.4802-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M18.3359,60.46405V67.2672a.37522.37522,0,0,0,.75,0V60.46405a.37522.37522,0,0,0-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M10.90433,37.519,9.53047,57.08111c-.03382.48168.71633.47956.75,0L11.65433,37.519c.03383-.48168-.71632-.47956-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M1.85326,38.07294l2.02643,32.5958c.02981.47944.78.48282.75,0L2.60326,38.07294c-.0298-.47945-.78-.48282-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M.00878,39.28664l7.10214,34.863c.09628.4726.81936.27258.72321-.19938L.732,39.08726c-.09628-.4726-.81935-.27257-.72321.19938Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M12.86379,67.30308l2.17617,6.268c.15732.45315.88211.25831.7232-.19938L13.587,67.1037c-.15732-.45314-.88211-.2583-.7232.19938Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M27.9737,43.73964v22.3937a.37522.37522,0,0,0,.75,0V43.73964a.37522.37522,0,0,0-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M32.765,29.95583l3.12427,20.40851c.07281.47558.79561.27356.72321-.19938L33.48823,29.75645c-.0728-.47557-.79561-.27356-.72321.19938Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M31.9422,11.99161V25.5979a.37522.37522,0,0,0,.75,0V11.99161a.37522.37522,0,0,0-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M17.25911,52.24023l1.1626,19.84084c.02811.47972.7783.483.75,0l-1.1626-19.84084c-.02811-.47972-.7783-.48295-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M9.6505,55.51835l5.31113,18.16358a.37532.37532,0,0,0,.72321-.19938L10.37371,55.319a.37532.37532,0,0,0-.72321.19938Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M26.55637,57.6294v24.378a.37522.37522,0,0,0,.75,0V57.6294a.37522.37522,0,0,0-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M33.8778,60.46808,35.5705,80.8782c.03961.47758.79.48181.75,0L34.6278,60.46808c-.03961-.47758-.79-.4818-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M22.35126,74.6402l-.8504,13.6063c-.03013.482.72.48021.75,0l.8504-13.6063c.03012-.482-.72-.48021-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M14.21491,59.9072,12.51308,82.01735c-.03705.48134.71314.479.75,0L14.96491,59.9072c.03705-.48134-.71314-.479-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M5.85358,56.88334l4.52918,22.67842c.09445.47292.8175.27274.72321-.19938L6.57679,56.684c-.09445-.47292-.8175-.27274-.72321.19937Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M24.57028,42.14243l6.79623,26.64743a.37532.37532,0,0,0,.72321-.19938L25.29349,41.94305a.37532.37532,0,0,0-.72321.19938Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M39.31228,25.31444V40.62153a.37522.37522,0,0,0,.75,0V25.31444a.37522.37522,0,0,0-.75,0Z"
                    fill="#e4e4e4"
                />
                <path
                    d="M32.20467,12.55612,29.65726,34.66679c-.05514.47863.69531.47468.75,0l2.54741-22.11067c.05515-.47864-.69531-.47469-.75,0Z"
                    fill="#e4e4e4"
                />
            </pattern>
        </defs>
        <path
            d="M553.87152,317.45656a34.12351,34.12351,0,0,0-45.19727-28.82093l2.47412.25245a45.21117,45.21117,0,0,0-27.63086,17.65179,39.14649,39.14649,0,0,0-6.20263,31.92517c2.80371,10.83356,11.08007,20.23261,21.67382,23.83868,5.89063,2.00531,12.21387,2.25025,18.4336,2.46174,4.03711.13726,8.14844.25994,12.04053-.82166a25.29322,25.29322,0,0,0,12.59472-8.78,51.67649,51.67649,0,0,0,7.4502-13.64172C552.52435,333.8636,554.69085,325.64686,553.87152,317.45656Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#2f2e41"
        />
        <path
            d="M641.831,816.594c-8.79548-33.08039,10.73693-65.32354,33.56046-87.64462,12.16363-11.89584,25.70964-22.23658,38.91937-32.918a415.65263,415.65263,0,0,0,36.15079-32.55711c22.02173-22.53988,41.23144-48.29877,53.95275-77.25417,12.09717-27.53475,17.8153-57.529,14.9829-87.57264-2.6863-28.49387-12.89968-56.5226-31.68743-78.38388q-3.51169-4.0862-7.38812-7.83319c-1.38587-1.34567-3.50952.77339-2.12132,2.12132,20.98,20.37151,33.19114,47.97857,37.32381,76.69941,4.26188,29.61882-.12942,59.81441-11.205,87.50561-11.79852,29.49864-30.56028,55.821-52.31808,78.79115a385.92356,385.92356,0,0,1-34.903,32.35939c-13.12383,10.811-26.76083,21.02543-39.25754,32.57843-23.03182,21.29252-43.732,50.58154-40.88841,83.5511a66.35642,66.35642,0,0,0,1.986,11.35476,1.50128,1.50128,0,0,0,2.89284-.79752Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#f0f0f0"
        />
        <path
            d="M733.93992,628.26918a306.69742,306.69742,0,0,1-31.51565-75.87111,312.81594,312.81594,0,0,1-10.52781-81.49126c.10076-27.50732,3.44056-55.70322,11.96815-81.93469,8.0933-24.8956,22.59412-48.63763,45.61578-62.2231a74.51564,74.51564,0,0,1,38.75409-10.23425c14.37543.13621,28.55211,3.92,41.50459,10.04691,12.68546,6.00058,24.28517,14.08726,35.00072,23.10194A257.32123,257.32123,0,0,1,893.25491,377.665c17.36624,19.97014,31.554,43.09289,39.47847,68.44629,8.25853,26.42231,9.70614,55.32307,2.04161,82.06482-7.36161,25.68484-23.48871,47.57711-43.28753,65.1627-21.39627,19.00448-46.74161,32.81553-72.299,45.35022-6.39677,3.13731-12.82822,6.2032-19.25974,9.26842-1.742.83021-.22158,3.41765,1.51415,2.59041,26.94915-12.84379,54.16476-25.79986,78.188-43.73209,21.19689-15.82251,40.091-35.685,51.33145-59.87952,11.8867-25.58564,14.32265-54.67739,9.39588-82.2821-4.815-26.97854-16.89424-52.22858-33.06066-74.21945a242.87715,242.87715,0,0,0-26.691-30.46169,220.72244,220.72244,0,0,0-32.5505-26.52947c-23.91833-15.634-53.82981-25.07508-82.17585-17.02194C740.68,323.581,721.285,343.77225,709.7443,366.65506c-6.45937,12.80758-10.813,26.58133-13.91,40.55811a295.57217,295.57217,0,0,0-6.08029,41.8096A314.79143,314.79143,0,0,0,721.607,611.76451q4.563,9.17282,9.74252,18.01882a1.50112,1.50112,0,0,0,2.59041-1.51415Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#f0f0f0"
        />
        <path
            d="M714.93992,599.26918a306.69742,306.69742,0,0,1-31.51565-75.87111,312.81594,312.81594,0,0,1-10.52781-81.49126c.10076-27.50732,3.44056-55.70322,11.96815-81.93469,8.0933-24.8956,22.59412-48.63763,45.61578-62.2231a74.51564,74.51564,0,0,1,38.75409-10.23425c14.37543.13621,28.55211,3.92,41.50459,10.04691,12.68546,6.00058,24.28517,14.08726,35.00072,23.10194A257.32123,257.32123,0,0,1,874.25491,348.665c17.36624,19.97014,31.554,43.09289,39.47847,68.44629,8.25853,26.42231,9.70614,55.32307,2.04161,82.06482-7.36161,25.68484-23.48871,47.57711-43.28753,65.1627-21.39627,19.00448-46.74161,32.81553-72.299,45.35022-6.39677,3.13731-12.82822,6.2032-19.25974,9.26842-1.742.83021-.22158,3.41765,1.51415,2.59041,26.94915-12.84379,54.16476-25.79986,78.188-43.73209,21.19689-15.82251,40.091-35.685,51.33145-59.87952,11.8867-25.58564,14.32265-54.67739,9.39588-82.2821-4.815-26.97854-16.89424-52.22858-33.06066-74.21945a242.87715,242.87715,0,0,0-26.691-30.46169,220.72244,220.72244,0,0,0-32.5505-26.52947c-23.91833-15.634-53.82981-25.07508-82.17585-17.02194C721.68,294.581,702.285,314.77225,690.7443,337.65506c-6.45937,12.80758-10.813,26.58133-13.91,40.55811a295.57217,295.57217,0,0,0-6.08029,41.8096A314.79143,314.79143,0,0,0,702.607,582.76451q4.563,9.17282,9.74252,18.01882a1.50112,1.50112,0,0,0,2.59041-1.51415Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#f0f0f0"
        />
        <path
            d="M441.837,543.04846a12.514,12.514,0,0,1,4.87562-18.559L481.38882,415.914l24.1502,12.54528L463.8595,531.24056a12.58187,12.58187,0,0,1-22.02254,11.8079Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#ffb6b6"
        />
        <path
            d="M470.16649,430.92824a5.60508,5.60508,0,0,1,.02634-4.67218l11.877-25.535a15.58316,15.58316,0,0,1,29.908,8.766l-3.96964,27.97311a5.61114,5.61114,0,0,1-7.14932,4.59123l-27.17168-8.05054A5.60483,5.60483,0,0,1,470.16649,430.92824Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#2f2e41"
        />
        <path
            d="M570.34466,435.23756c-8.25268,21.6095-6.144,44.27539,4.02,67.76251h-72c2.8938-16.71118,1.72-32.24139-9-45Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#ffb6b6"
        />
        <path
            d="M486.07879,395.52608l21.3086-15.49716L542.256,370.3432l29.05718,5.81143,1.70815,16.05659a193.849,193.849,0,0,1-2.67672,58.52347v0L494.79594,468.169l-7.74858-13.56s-17.4343-17.4343-3.87429-36.80576Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#2f2e41"
        />
        <path
            d="M671.15463,287.12889a12.51407,12.51407,0,0,1-11.00353,15.72044l-70.14516,89.83722-18.30812-20.13527,74.70729-81.97562a12.58187,12.58187,0,0,1,24.74952-3.44677Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#ffb6b6"
        />
        <path
            d="M605.735,382.49019a5.60512,5.60512,0,0,1-1.64341,4.37369L584.10345,406.7026a15.58316,15.58316,0,0,1-25.01867-18.58489L572.5,363.25238a5.61112,5.61112,0,0,1,8.29719-1.83l22.69969,16.9657A5.60486,5.60486,0,0,1,605.735,382.49019Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#2f2e41"
        />
        <polygon
            points="228.931 716.837 239.964 716.837 245.213 674.28 228.929 674.28 228.931 716.837"
            fill="#ffb6b6"
        />
        <path
            d="M482.43688,791.48722l17.44141-1.041v7.47266l16.582,11.45215a4.6677,4.6677,0,0,1-2.65234,8.50879H493.04333l-3.5791-7.3916-1.39746,7.3916h-7.8291Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#2f2e41"
        />
        <polygon
            points="306.931 716.837 317.964 716.837 323.213 674.28 306.929 674.28 306.931 716.837"
            fill="#ffb6b6"
        />
        <path
            d="M560.43688,791.48722l17.44141-1.041v7.47266l16.582,11.45215a4.6677,4.6677,0,0,1-2.65234,8.50879H571.04333l-3.5791-7.3916-1.39746,7.3916h-7.8291Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#2f2e41"
        />
        <path
            d="M593.4848,558.49007v21.7l-2.12012,26.51s-.66992,23.15-1.48,41.41c-.48975,11.1-1.04,20.39-1.52,21.59-.58984,1.47-.45019,5.48-.10986,9.69.3999,4.87,1.06006,10.01,1.16992,11.73.21,3.21-1.49024,17-1.49024,17s-1.8999,34.58-4.65966,37.34c-2.76026,2.76,0,10.61,0,10.61-12.93994,9.54-29.28028-2.12-29.28028-2.12,3.61035-5.75-2.12011-45-2.12011-45s-.83985-13.4-2.53955-17c-1.7002-3.6-2.13038-26.09-2.13038-26.09v-33.31s-4.46-6.82-5.08984-10,0-14.42,0-14.42l-4.66016-15.7-3.18994,12.3s-8.25976,34.37-9.75,38.61c-1.48974,4.24.84033,8.49.84033,8.49s2.12989,6.54,0,9.54a21.45549,21.45549,0,0,0-3,6.16s-1.09033,12.52-3,17c-1.91015,4.48-3.60009,10-3.60009,10s.4497,8.46-1.46,10.37c-1.91016,1.91-2.54,11.46-2.54,11.46s.41992,10.81-2.13037,14.63c-2.54981,3.82-2.54981,25.46-2.54981,25.46a29.67841,29.67841,0,0,1-29.87988,1.27s2.52-2.36.60986-13.36c-1.90967-11,1.37012-40.95,1.37012-40.95s-.41992-7.6,1.69971-11c2.12011-3.4-1.06983-9.97-.21973-12.94s3.3999-19.1,3.3999-19.1-1.06006-39.67-1.06006-41.79a27.6995,27.6995,0,0,0-3-10.82c-1.90966-3.39,0-28,0-28s4.5-12.72,3-15.48c-1.5-2.76,1-7.64,1-7.64s-2.33007-5.73,0-6.79,2.76026-5.3,2.76026-5.3l1.61963-20.58s.61035-9.34,2.55029-9.76c1.93994-.42005,1.08984-4.02,3-6.57a17.93017,17.93017,0,0,0,1.8999-5.52c.27-1.18.52-2.41.73-3.59.6001-3.2.97022-5.96.97022-5.96,12.52,10.4,78.34961,3.11,78.41992,3.11,4.17969.91,9.1499,15.42,12.73974,28.07A127.592,127.592,0,0,1,593.4848,558.49007Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#2f2e41"
        />
        <circle
            cx={517.02198}
            cy={334.3487}
            r={29.87165}
            transform="translate(-210.49111 651.68052) rotate(-73.09108)"
            fill="#ffb6b6"
        />
        <path
            d="M483.25808,339.55485l0,0,11.567-2.40685,2.12984-15.35044,3.99352,14.0763,5.52858-1.15038,1.24246-8.95443,2.3297,8.21114,41.07976-8.54781,0,0a31.62173,31.62173,0,0,0-37.40045-24.51681l-5.95365,1.23883A31.62173,31.62173,0,0,0,483.25808,339.55485Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#2f2e41"
        />
        <path
            d="M601.59373,114.94182l-.076.14584a64.6843,64.6843,0,0,0-59.72048,114.58615l-.076.14584,114.87783,59.8725,59.8725-114.87783a64.772,64.772,0,1,0-114.87783-59.8725Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#00b0ff"
        />
        <path
            d="M703.94249,774.14671l-.01921.03686A16.34883,16.34883,0,0,0,688.829,803.145l-.01921.03686L717.845,818.31452l15.13266-29.03515a16.371,16.371,0,1,0-29.03515-15.13266Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#ff6584"
        />
        <path
            d="M318.77573,405.99185v.16446a64.6843,64.6843,0,0,0,.00006,129.215v.16446l129.54394-.00006-.00006-129.54393a64.772,64.772,0,1,0-129.54394,0Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#00b0ff"
        />
        <path
            d="M922.49988,819.90729h-647.294a1.19068,1.19068,0,1,1,0-2.38136h647.294a1.19068,1.19068,0,1,1,0,2.38136Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#cacaca"
        />
        <path
            d="M819.44108,528.37375a57.16908,57.16908,0,0,0,36.198-13.21358,55.47488,55.47488,0,0,0,19.16572-34.28885,56.61647,56.61647,0,0,0-1.10816-22.70921,1.50129,1.50129,0,0,0-2.89284.79752,53.9543,53.9543,0,0,1-4.07408,37.5331,53.35448,53.35448,0,0,1-47.2886,28.881,1.5009,1.5009,0,0,0,0,3Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#f0f0f0"
        />
        <path
            d="M587.79266,138.71474c-8.53553-8.10264-22.01337-8.08143-31.871-2.44084-11.06235,6.32992-16.07749,19.40985-15.824,31.691.28532,13.822,7.04345,26.33726,15.76947,36.70292A146.21811,146.21811,0,0,0,587.99295,232.437a161.4139,161.4139,0,0,0,38.51515,18.35436q2.50987.80924,5.04561,1.53376a1.50129,1.50129,0,0,0,.79752-2.89284,157.49379,157.49379,0,0,1-68.89891-40.87946c-9.073-9.29262-17.136-20.461-19.61587-33.45986-2.19-11.47968.23036-24.80866,9.12789-32.98573,8.64919-7.94885,23.78712-9.73863,32.707-1.27113,1.4,1.329,3.525-.78883,2.12132-2.12132Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#fff"
        />
        <path
            d="M316.5578,437.54884c-11.52969-1.43375-22.35769,6.67563-26.84833,16.91864-5.03588,11.4867-1.64061,24.72757,5.66408,34.4355,8.04476,10.69149,20.23018,16.92385,32.96783,20.23221,13.673,3.55131,28.18755,4.51,42.26239,3.85414a161.66862,161.66862,0,0,0,48.67642-9.89456c1.79416-.66617,1.01793-3.56691-.79752-2.89284-26.00061,9.654-54.9456,12.6997-82.28723,7.60291-12.22158-2.27824-24.66867-6.62473-33.7761-15.4179-8.2522-7.96746-14.09013-20.034-11.60661-31.68668,2.46781-11.579,13.4767-21.677,25.74507-20.15142,1.91114.23765,1.89388-2.7645,0-3Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#fff"
        />
        <path
            d="M508.37629,290.59909c2.78866-11.14769-2.61263-23.1049-10.97582-30.98555S478.124,247.20373,467.32265,243.2822c-8.61366-3.12727-18.70291-5.84737-26.49879-1.03082-6.2643,3.87029-8.98166,11.388-12.56273,17.822a58.41151,58.41151,0,0,1-71.78588,26.19541c4.62329,13.42145,20.54829,19.98636,34.71442,19.07467s27.25027-7.4355,40.4466-12.66688,27.87571-9.27329,41.43883-5.08376c5.72453,1.76826,10.93081,4.92405,16.59875,6.86609s12.30082,2.52037,17.35214-.70162"
            transform="translate(-257.24239 -80.09271)"
            fill="#2f2e41"
        />
        <path
            d="M706.78927,698.88673l-9.65653-16.99723a1.50112,1.50112,0,0,0-2.59041,1.51416l9.65653,16.99723a1.50112,1.50112,0,0,0,2.59041-1.51416Z"
            transform="translate(-257.24239 -80.09271)"
            fill="#f0f0f0"
        />
        <path
            d="M501.12445,488.18007l-.71-.88A5.56168,5.56168,0,0,1,501.12445,488.18007Z"
            transform="translate(-257.24239 -80.09271)"
            fill="url(#e316dee7-4006-4b05-87d0-6679d9899947-180)"
        />
    </svg>
);
export default RespectImg;
