import React, { useEffect } from 'react'
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';
import styles from "./TermsCondition.module.css"


export default function TermsCondition() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navbar />

            <div className={styles.culturediv1}>
                <h1>Terms & Conditions</h1>
                <p>
                    <h4>"DJT Retailers Private Limited"</h4>

                    “Deerika” is a trademark of DJT Retailers Private Limited (“Company”), a Company incorporated under the provisions of Companies Act, 2013 having its corporate office at Plot No. A2B, 8th Floor, Sector-125, Noida-201303. The domain name www.djtretailers.com is owned by the Company.
                    <br></br>
                    It is strongly recommended that you read and understand the terms and conditions carefully, as by accessing this site, you agree to be bound by the same and acknowledge that it constitutes an agreement between you and the Company. If you do not agree with the terms, you should not use or access the site for any purpose whatsoever and in that event the Company shall not be liable or responsible.
                    <br></br>

                    These terms may be subject to amendment, so you should carefully read them prior to placing your order.
                </p>
            </div>
            <div className={styles.culturediv1}>
                <h2>1. Service Overview</h2>
                <p>
                    Products offered through the Website and/or Mobile App are available to only select geographies in India and are subject to restrictions based on business hours and regulations, as enforceable by law.

                </p>
            </div>
            <div className={styles.culturediv1}>
                <h2>2. Access to Website</h2>
                <p>
                    Deerika/Company hereby provides its conditional permission for you to access the Website in accordance with these terms and conditions provided that you use the website on your continued and absolute compliance with these terms and conditions. You shall not rent, transfer, assign, commercially exploit, resell or sublicense access to the services/products available on the website. You may only use this website solely for (1) your personal, non commercial use (2) you may download a single copy of the materials from the website only for your personal, non-commercial home use. You agree not to use or launch, or cause to be used or launched, any automated/computerized/analog/hybrid system or program in connection with the website or online ordering, including without limitation, publishing or distributing vouchers or codes, robots, spiders, offline readers, place pop-up windows over its pages, or otherwise effect the display of its pages and anything of similar nature.
                    <br></br>
                    Except as expressly provided herein, you may not modify, copy, distribute, broadcast, transmit, reproduce, publish, license, transfer, sell, mirror, frame, deep link, or otherwise use any information or material obtained from or through this website without the written permission of the Company.
                    <br></br>
                    We always try to provide you uninterrupted access to the website of Deerika/Company and that transmissions will be error-free. However, due to the nature of the internet, this cannot be guaranteed.
                </p>
            </div>
            <div className={styles.culturediv1}>
                <h2 >3. Use of our Website </h2>

                <p >These terms and conditions are applicable only for the use of the website and they replace all other conditions, except, prior writing agreement of the Deerika/Company. These terms are important for both you and Deerika/Company as they have been designed to create a legally binding agreement between us, protecting your rights as a valued customer and our rights as a business. You agree that, by placing your order, you unreservedly accept these terms, having read them.
                    <br></br>
                    You agree that:
                    <br></br>

                    1. You may only use the Website/Mobile App to make legitimate enquiries or orders.
                    <br></br>

                    2. You will not make any speculative, false or fraudulent orders. If we are reasonably of the opinion that such an order has been made, we shall be entitled to cancel the order and inform the relevant authorities.
                    <br></br>

                    3. You also undertake to provide correct and accurate e-mail, postal and/or other contact details to us and acknowledge that we may use these details to contact you in the event that this should prove necessary (see our Privacy Policy for more information on how we use your personal information).
                    <br></br>

                    4. If you do not give us all of the information that we need, we may not be able to complete your order.
                </p>
            </div>
            <div className={styles.culturediv1}>
                <h2 >4. Electronic Communication</h2>

                <p >By sharing your Contact Information, you have consented to be contacted by us on the said contact number even if the said contact information is registered under “National Do Not Call” (NDNC) Registry.

                    <br></br>
                    Once you provide us with the mobile number, you will receive a message from us such as payment confirmation, bill reminders and other relevant messages, including information to inform you of new or additional services offered by us from which you may benefit. Message frequency depends upon your geographical location that we use to send you message relevant to our services available in the area.
                </p>
            </div>
            <div className={styles.culturediv1}>
                <h2 >5. User Generated Content</h2>
                <p >
                    In these terms and conditions, "your user content" means material (including but not limited to text, images, audio material, video material and audio-visual material) that you submit to our website, for that you submit to our website, for whatever purpose.
                    <br></br>
                    Your user content must not be defamatory, vulgar, obscene, offensive, libellous, slanderous or illegal or unlawful in any way and must not infringe any third party's rights, and must not be capable of giving rise to legal action whether against you or us or a third party (in each case under any applicable law).
                    <br></br>
                    You must not submit any user content to the website that is or has ever been the subject of any threat or actual legal proceedings or other similar complaint.
                    <br></br>
                    We reserve the right to edit or remove any material submitted to our website, or stored on our servers, or hosted or published upon our website without prior notice or explanation.
                    <br></br>
                    Notwithstanding our rights under these Terms and Conditions in relation to user content, we do not undertake to monitor the submission of such content to, or the publication of such content on, our website.

                </p>
            </div>
            <div className={styles.culturediv1}>
                <h2>6. Limitation of Liability</h2>
                <p >
                    Under no circumstances, including but not limited to negligence, shall the DJT Retailers, its employees and directors, its suppliers and its third party advisors be liable to the User for any direct, indirect, incidental, special or consequential damages or any damages whatsoever including punitive or exemplary (including, but not limited to, damages caused by any virus, personal injury, loss of profits, data or other intangible, business interruption, loss of privacy, or any other pecuniary loss), arising out of or in any way connected with the use/delivery/performance of this Web Site, with the delay or inability to use this Web Site or any links or items on the Web Site, the provision of or failure to provide services, or for any information, software, products, services and related graphics obtained through this Web Site, or otherwise arising out of the use of this Web Site, whether based on contract, tort, strict liability or otherwise, or for cost of procurement of substitute goods and repair & correction services or resulting from the use of this Web Site or obtained or messages received or transactions entered into through or from the Web Site or resulting from unauthorized access to or alteration of User's transmissions or data, even if the Bimakaro have been advised of the possibility of such damages.
                    <br></br>
                    Your user content must not be defamatory, vulgar, obscene, offensive, libellous, slanderous or illegal or unlawful in any way and must not infringe any third party's rights, and must not be capable of giving rise to legal action whether against you or us or a third party (in each case under any applicable law).
                    <br></br>
                    You must not submit any user content to the website that is or has ever been the subject of any threat or actual legal proceedings or other similar complaint.
                    <br></br>
                    We reserve the right to edit or remove any material submitted to our website, or stored on our servers, or hosted or published upon our website without prior notice or explanation.
                    <br></br>
                    Notwithstanding our rights under these Terms and Conditions in relation to user content, we do not undertake to monitor the submission of such content to, or the publication of such content on, our website.

                </p>
            </div>
            <Footer />
        </>
    )
}


// 