import React, { useEffect } from 'react'
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';
import styles from "./PrivacyPolicy.module.css"


export default function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navbar />

            <div className={styles.culturediv1}>
                <h1>Privacy Policy</h1>

                <p>This document outlines how DJT processes and manages personal data and:</p>
                <ul>
                    <li>Identifies the data controller.</li>
                    <li>Explains the lawful basis for processing personal data.</li>
                    <li>Outlines the personal data held and processed.</li>
                    <li>Outlines the scope of the special category personal data held and processed.</li>
                    <li>Outlines the process of Subject Access Requests.</li>
                </ul>
                <h3>1. Data Controller</h3>
                <p> The Data Controller is DJT Retailers</p>
                <h3>2. Contact</h3>
                <p> If you have any questions about this policy or for more information about how we use your data or would like
                    to exercise any of your rights contact DJT Retailers.</p>

                <h3>3. Lawful basis for processing</h3>
                <p> All processing is carried out by consent or either under the legitimate interest of DJT Retailers, or public interest.
                    These cover processing to conduct casework, campaigning and communication. Where processed under the lawful
                    basis of a task carried out in the public interest, it is to support or promote democratic engagement. This
                    includes fundraising activity in order to support democratic engagement.
                </p>
                <h3>4. Data sources</h3>
                <p> Data held is that provided by you when you contact us and correspondence with third parties in response to
                    cases taken up on your behalf. We may also hold data that you provide when we contact you – for instance, if we
                    ask you to participate in a survey or petition. If you do not wish for us to contact you by telephone please do
                    not provide this information. We perform a database lookup automatically upon any form submission to include
                    electoral boundary data.
                </p>
                <h3>5. Data Security</h3>
                <p> Personal data is stored electronically and securely and we store our data on the DJT Retailers servers in the India.
                </p>


            </div>
            <Footer />
        </>
    )
}

