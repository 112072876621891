import React, { useEffect } from 'react'
import styles from "./Culture.module.css"
import FunImg from '../ImagesComponent/FunImg'
import WorkImg from '../ImagesComponent/workImg'
import TrustImg from '../ImagesComponent/TrustImg'
import CommunicationImg from "../ImagesComponent/CommunicationImg"
import JobdetailImg from "../ImagesComponent/Jobdetail"
import PortalImg from "../ImagesComponent/PortalImg"
import SupportImg from '../ImagesComponent/SupportImg'
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar'
import { Button } from 'antd'




export default function Culture() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navbar />

            <div className={styles.culturediv1}>
                <h1>Our Culture</h1>
                <p>
                    DJT Retailers prefers to have incredibly high standards and to have exceptional people who enjoy pushing them selves to perform at the highest levels every day. We’re  little different in the way we do things, being different allows us to look at the traditional way and place customers at the center of it all, then we do things which makes life convenient for the customer and not necessarily for us!
                </p>
            </div>

            <div className={styles.magnifestdiv}>
                <h2>Magnificient Cultural Diversity</h2>

                <div className={styles.magnifestdiv1}>
                    <div className={`${styles.magnifestdiv2} ${styles.fun}`}>
                        <header className={styles.headerdiv}> <span className={styles.spandiv}>1</span><h2>Fun</h2></header>
                        <p>Doing work with full dull faces will bring no creativity to your work. Make sure you enjoy your work by making friends, trying new things, and recreating yourself by adding new skills.</p>
                        <div className={styles.imgdiv}><FunImg /></div>
                    </div>
                    <div className={`${styles.magnifestdiv2} ${styles.work}`}>
                        <header className={styles.headerdiv}> <span className={styles.spandiv}>2</span><h2>Work recognition award</h2></header>
                        <p>Work, if done up to the mark; deserves appreciation as it encourages everyone to do more better with more determination. DJT Retailers highlights the Star performer of the month certificate and a gift voucher are being added to their cartful of happiness.</p>
                        <div className={styles.imgdiv}>
                            <WorkImg />

                        </div>
                    </div>
                </div>

                <div className={`${styles.magnifestdiv1} ${styles.trust}`}>
                    <div className={styles.magnifestdiv2}>
                        <header className={styles.headerdiv}> <span className={styles.spandiv}>3</span><h2>Trust</h2></header>
                        <div className={styles.trustdiv}>
                            <p>Every employee is being involved in our organization as a member of the family with the trust that everyone will do their assigned work with great dedication.</p>
                            <div><TrustImg className={styles.TrustImg} /></div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.magnifestdiv1} ${styles.communication}`}>
                    <div className={styles.magnifestdiv2}>
                        <header className={styles.headerdiv}> <span className={styles.spandiv}>4</span><h2>Communication</h2></header>
                        <p>Transparent communication between the employees makes the vision clear to acheive the target wihtout any delays. The employee is free to contact the manager, head of any department, or managing director, if problem are not being sorted out on time.</p>
                        <div className={styles.imgdiv}>
                            <CommunicationImg />
                        </div>
                    </div>
                    <div className={`${styles.magnifestdiv2} ${styles.job}`}>
                        <header className={styles.headerdiv}> <span className={styles.spandiv}>5</span><h2>Job Duties</h2></header>
                        <p>An employee needs to understand their responsibility towards their job and make sure to complete the aligned task on time so as to contribute to the terms of the company's success.</p>
                        <div className={styles.imgdiv}>
                            <JobdetailImg />
                        </div>
                    </div>
                </div>

                <div className={`${styles.magnifestdiv1} ${styles.portal}`}>
                    <div className={styles.magnifestdiv2}>
                        <header className={styles.headerdiv}> <span className={styles.spandiv}>6</span><h2>Grievance Portal</h2></header>
                        <div className={styles.trustdiv}>
                            <p>For any sort of grievances, an individual can walk to HR department or can talk to the Managing Director. No restriction, no barriers, and no judgments. DJT considers every employee as part of the family and promises to take care of you.</p>
                            <div ><PortalImg /></div>
                        </div>
                    </div>
                </div>

            </div>


            <div className={styles.supportdiv}>
                <div><SupportImg /></div>
                <div><h1>Want a support?</h1>
                    <p>Talk to us and we will help you find a policy that fits your criteria. It's free and we will never spam you. Pinky Promise.</p>
                    <Button type="primary" htmlType="submit" onClick={() => window.location = 'mailto: help@deerika.in'}>
                        Mail Us
                    </Button>
                </div>
            </div>
            <Footer />
        </>
    )
}
