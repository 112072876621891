import * as React from "react";
const JobdetailImg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width={320}
        height={270}
        viewBox="0 0 781.67686 642.211"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <path
            d="M940.60615,771.1055v-72.34S968.79781,750.05147,940.60615,771.1055Z"
            transform="translate(-209.16157 -128.8945)"
            fill="#f1f1f1"
        />
        <path
            d="M942.34762,771.09277,889.058,722.17153S945.90319,736.08706,942.34762,771.09277Z"
            transform="translate(-209.16157 -128.8945)"
            fill="#f1f1f1"
        />
        <circle
            id="a0c15788-f4f4-4acc-aa12-f27a30ea141c"
            data-name="Ellipse 44"
            cx={238.42719}
            cy={11.01739}
            r={11.01739}
            fill="#f1f1f1"
        />
        <circle
            id="e9b5f50e-78d4-4cc2-9ed2-81635f80ef66"
            data-name="Ellipse 44"
            cx={210.42719}
            cy={39.01739}
            r={11.01739}
            fill="#f1f1f1"
        />
        <circle
            id="b8afa681-60ae-40dd-b0dc-0ba40dd0fb25"
            data-name="Ellipse 44"
            cx={203.42719}
            cy={536.01739}
            r={11.01739}
            fill="#f1f1f1"
        />
        <path
            d="M796.56868,435.09983"
            transform="translate(-209.16157 -128.8945)"
        />
        <polygon
            points="580.071 629.149 568.088 629.149 567.402 583.645 580.071 582.928 580.071 629.149"
            fill="#9f616a"
        />
        <path
            d="M792.30048,769.67067l-38.66137-.00048v-.5a15.06814,15.06814,0,0,1,15.051-15.05079h23.61035Z"
            transform="translate(-209.16157 -128.8945)"
            fill="#2f2e41"
        />
        <polygon
            points="648.668 615.343 637.683 620.132 618.87 578.693 630.198 572.974 648.668 615.343"
            fill="#9f616a"
        />
        <path
            d="M829.84809,769.11989l-.19971-.45849a15.05086,15.05086,0,0,1,7.78248-19.81153l21.64306-9.43506,6.21436,14.25538Z"
            transform="translate(-209.16157 -128.8945)"
            fill="#2f2e41"
        />
        <circle cx={601.36472} cy={243.99687} r={25.96509} fill="#9f616a" />
        <polygon
            points="647.842 593.621 614.282 511.638 630.582 434.928 599.659 433.25 582.639 519.069 625.548 604.168 647.842 593.621"
            fill="#2f2e41"
        />
        <polygon
            points="611.165 419.586 595.344 517.63 590.587 611.602 563.276 611.362 562.263 431.572 611.165 419.586"
            fill="#2f2e41"
        />
        <polygon
            points="632.869 425.177 631.313 439.378 553.893 442.842 559.135 421.489 632.869 425.177"
            fill="#cbcbcb"
        />
        <path
            d="M768.41376,580.87944a8.99962,8.99962,0,0,1,1.535-12.70756,9.256,9.256,0,0,1,1.2506-.81637l30.36546-114.10958,17.99971,7.91025L783.89263,571.42612a9.01624,9.01624,0,0,1-2.65385,10.88367A9.178,9.178,0,0,1,768.41376,580.87944Z"
            transform="translate(-209.16157 -128.8945)"
            fill="#9f616a"
        />
        <path
            d="M787.60615,423.8489l3.581-7.39754s17.63442-15.24476,36.23429-6.10645l2.867,8.54L845.458,443.38173l-3.427,113.148s-63.28919-4.81262-78.976.92146l6.89531-89.65908Z"
            transform="translate(-209.16157 -128.8945)"
            fill="#3f3d56"
        />
        <path
            d="M790.90009,567.44753l-26.72216-7.47461,24.71289-77.23584,2.23755-43.12207a15.59245,15.59245,0,0,1,5.82153-11.24951,19.16451,19.16451,0,0,1,14.79883-4.07959c8.73217,1.1958,15.15527,7.873,15.2727,15.877l.00049.03906-.00537.03858-8.58228,60.44189-2.75195,9.83887Z"
            transform="translate(-209.16157 -128.8945)"
            fill="#cbcbcb"
        />
        <path
            d="M822.29554,393.2436l5.9108,1.49429,8.95838-9.52365s16.464-38.19073-19.9247-44.03491-28.44189,8.9101-33.307,18.16253l14.86918,3.866L812.1845,376.8875s8.73238-6.39611,11.30058-.59477S822.29554,393.2436,822.29554,393.2436Z"
            transform="translate(-209.16157 -128.8945)"
            fill="#2f2e41"
        />
        <path
            d="M615.25556,614.74294h-406.094V208.64919h406.094Z"
            transform="translate(-209.16157 -128.8945)"
            fill="#fff"
        />
        <path
            d="M615.25556,614.74294h-406.094V208.64919h406.094Zm-400.094-6h394.094V214.64919h-394.094Z"
            transform="translate(-209.16157 -128.8945)"
            fill="#e5e5e5"
        />
        <rect
            x={83.7309}
            y={254.32923}
            width={260.44083}
            height={9.1937}
            fill="#63d1ff"
        />
        <rect
            x={83.7309}
            y={278.23282}
            width={260.44083}
            height={9.1937}
            fill="#63d1ff"
        />
        <rect
            x={83.7309}
            y={302.13642}
            width={260.44083}
            height={9.1937}
            fill="#63d1ff"
        />
        <circle cx={67.92227} cy={260.27301} r={6} fill="#63d1ff" />
        <rect
            x={83.7309}
            y={151.32923}
            width={260.44083}
            height={9.1937}
            fill="#e5e5e5"
        />
        <rect
            x={83.7309}
            y={175.23282}
            width={260.44083}
            height={9.1937}
            fill="#e5e5e5"
        />
        <rect
            x={83.7309}
            y={199.13642}
            width={260.44083}
            height={9.1937}
            fill="#e5e5e5"
        />
        <circle cx={67.92227} cy={156.27301} r={6} fill="#e5e5e5" />
        <rect
            x={83.7309}
            y={358.32923}
            width={260.44083}
            height={9.1937}
            fill="#e5e5e5"
        />
        <rect
            x={83.7309}
            y={382.23282}
            width={260.44083}
            height={9.1937}
            fill="#e5e5e5"
        />
        <rect
            x={83.7309}
            y={406.13642}
            width={260.44083}
            height={9.1937}
            fill="#e5e5e5"
        />
        <circle cx={67.92227} cy={363.27301} r={6} fill="#e5e5e5" />
        <path
            d="M989.83843,770.8489h-299a1,1,0,0,1,0-2h299a1,1,0,0,1,0,2Z"
            transform="translate(-209.16157 -128.8945)"
            fill="#cbcbcb"
        />
    </svg>
);
export default JobdetailImg;
