import React, { useEffect } from 'react'
import styles from "./Value.module.css"
import SupportImg from '../ImagesComponent/SupportImg'
import RespectImg from '../ImagesComponent/RespectImg'
import CollaborationImg from '../ImagesComponent/CollaborationImg'
import ExcellenceImg from '../ImagesComponent/ExcellenceImg'

import PreserveImg from '../ImagesComponent/PerserveImg'

import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar'
import { Button } from 'antd'




export default function Value() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navbar />

            <div className={styles.culturediv1}>
                <h1>Our Values</h1>
                <p>
                    DJT Retailers. emphasizes on the following principles of values. This is a list of important principles of values and it should give you an idea about what to expect from us.
                </p>
            </div>

            <div className={styles.magnifestdiv}>
                <h2 className={styles.val}>Our Values</h2>
                <div className={`${styles.magnifestdiv1} ${styles.ourVal}`}>
                    <div className={`${styles.magnifestdiv2} ${styles.Respect}`}>
                        <header className={styles.headerdiv}> <span className={styles.spandiv}>1</span><h2>Respect</h2></header>
                        <p>Being mindful of the sensibilities of and extend courtesy, transparency and dignity to all our stakeholders at all times.</p>
                        <div className={styles.imgdiv}>
                            <RespectImg />
                        </div>
                    </div>
                    <div className={`${styles.magnifestdiv2} ${styles.Collaboration}`}>
                        <header className={styles.headerdiv}> <span className={styles.spandiv}>2</span><h2>Collaboration</h2></header>
                        <p>Build relationships with all our stakeholders based upon mutual trust, respect and common interests, consistent with our values and objectives.</p>
                        <div className={styles.imgdiv}>
                            <CollaborationImg />
                        </div>
                    </div>
                </div>

                <div className={`${styles.magnifestdiv1} ${styles.Perseverance}`}>
                    <div className={styles.magnifestdiv2}>
                        <header className={styles.headerdiv}> <span className={styles.spandiv}>3</span><h2>Perseverance</h2></header>
                        <div className={styles.trustdiv}>
                            <p>Always face challenges and adversity with courage and commitment consistent with our values. never losing sight of our objectives.</p>
                            <div ><PreserveImg /></div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.magnifestdiv1} ${styles.Excellence}`}>
                    <div className={styles.magnifestdiv2}>
                        <header className={styles.headerdiv}> <span className={styles.spandiv}>4</span><h2>Excellence</h2></header>
                        <div className={styles.Excellence2}>
                            <p>Constantly strive to set high standards and benchmarks in all that we do everyday, consistent with the expectations of all our stakeholders.</p>
                            <div className={styles.imgdiv}>
                                <ExcellenceImg className={styles.ExcellenceImg} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.supportdiv}>
                <div><SupportImg /></div>
                <div><h1>Want a support?</h1>
                    <p>Talk to us and we will help you find a policy that fits your criteria. It's free and we will never spam you. Pinky Promise.</p>
                    <Button type="primary" htmlType="submit" onClick={() => window.location = 'mailto: help@deerika.in'}>
                        Mail Us
                    </Button>
                </div>
            </div>

            <Footer />
        </>
    )
}
