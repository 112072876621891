import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import Navbar from '../Components/Navbar'
import "./ContactUs.css"
import Footer from '../Components/Footer';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
    },
};

const onFinish = (values) => {
    console.log(values);
};

export default function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navbar />
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div className='contactdiv1'>
                    <h2 style={{ textAlign: "center" }}>Contact Us</h2>
                    <div>
                        <Form
                            {...layout}
                            name="nest-messages"
                            onFinish={onFinish}
                            style={{
                                maxWidth: 600,
                                marginBottom: "15px",
                            }}
                            validateMessages={validateMessages}
                        >
                            <Form.Item
                                name={['user', 'name']}
                                label="Name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input style={{ width: window.innerWidth < 500 ? "100%" : "60%" }} />
                            </Form.Item>
                            <Form.Item
                                name={['user', 'email']}
                                label="Email"
                                rules={[
                                    {
                                        type: 'email',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input style={{ width: window.innerWidth < 500 ? "100%" : "60%" }} />

                            </Form.Item>
                            <Form.Item name={['user', 'question']} label="Type a question">
                                <Input.TextArea style={{ width: window.innerWidth < 500 ? "100%" : "60%" }} />
                            </Form.Item>
                            <Form.Item className='btndiv'
                                wrapperCol={{
                                    ...layout.wrapperCol,
                                    offset: 8,
                                }}
                            >
                                <Button type="primary" htmlType="submit" style={{ margin: "0px" }}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>

                    </div>

                </div>
            </div>
            <Footer />

        </>

    )
}




