import * as React from "react";
const CommunicationImg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width={350}
        height={280}
        viewBox="0 0 962.19305 788.56758"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <title>{"online discussion"}</title>
        <polygon
            points="220.588 159.448 224.684 497.367 401.675 583.58 397.579 245.662 220.588 159.448"
            fill="#f2f2f2"
        />
        <polygon
            points="166.805 132.454 34.553 403.956 214 491.367 209.904 153.448 166.805 132.454"
            fill="#f2f2f2"
        />
        <polygon
            points="962.193 513.867 605 339.875 609.096 677.794 829.942 785.37 962.193 513.867"
            fill="#f2f2f2"
        />
        <polygon
            points="413.579 245.662 417.675 583.58 594.665 669.794 590.569 331.875 413.579 245.662"
            fill="#f2f2f2"
        />
        <rect
            x={910.28981}
            y={316.19193}
            width={22.24232}
            height={28.59727}
            transform="translate(-131.01766 -19.76422) rotate(-2.22071)"
            fill="#64d1fb"
        />
        <path
            d="M831.71009,415.85046s-30.779-14.70576-30.28646-2.00541S832.44884,434.901,832.44884,434.901Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#ffb9b9"
        />
        <path
            d="M848.59666,523.311s-14.52107,35.54159-19.88626,61.18854-13.53608,60.94229-3.02582,85.97363,40.39191,98.59934,40.69972,106.53706-2.55946,15.99856,3.79071,15.75232,40.90677-11.12588,42.30963-15.95007-11.79-17.032-11.79-17.032L868.11733,657.69813,902.1068,591.1929l24.51648,99.215,4.80184,123.82843s23.56691-7.27361,26.98824-1.04656c0,0,10.55875-14.71883,11.9-21.13056s-4.98653-128.59107-4.98653-128.59107,21.63236-139.16288,4.16937-138.4857S883.63268,483.794,848.59666,523.311Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#2f2e41"
        />
        <path
            d="M869.92877,786.41232s-16.10862,35.60315-17.63461,37.25226-12.02317,17.95548-4.08545,17.64767,26.68044-8.98428,40.47584-22.23869,31.98406-36.21877,30.335-37.74475-12.9466-5.85768-12.9466-5.85768Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#2f2e41"
        />
        <path
            d="M934.29239,806.17546s-.97192,15.937-3.90076,22.4103-2.55947,15.99856,5.37825,15.69075,28.45267-4.2832,28.45267-4.2832,1.21817-9.58683-.43093-11.11281-8.67647-18.74272-5.68607-23.62848S934.29239,806.17546,934.29239,806.17546Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#2f2e41"
        />
        <path
            d="M946.03561,288.99242s16.922,26.37263,23.51842,32.47655-11.9616,19.543-11.9616,19.543l-33.2153,4.46789s.41786-30.2249-1.29281-33.33843S946.03561,288.99242,946.03561,288.99242Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#ffb9b9"
        />
        <path
            d="M917.16508,323.50052s6.84267,12.4541,13.19285,12.20786,39.1961-14.23941,40.599-19.0636,10.87963,34.5566,10.87963,34.5566L901.465,492.64205,881.86045,479.0929l9.49913-83.04478,7.80153-44.82061Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#64d1fb"
        />
        <path
            d="M973.92139,450.08405c-3.206,6.70666-5.20835,13.70047-4.9307,20.86031.01231.31752.02462.635.05282.95187.76057,14.69328,6.17341,31.68645,8.11534,44.04442,1.40908,8.86485,1.00808,15.33552-4.1797,16.85636-12.57723,3.66758,1.83379,6.28861-46.826,22.48493s-57.15159,2.21624-57.27471-.95885,6.89116-27.29606,5.24206-28.822-23.07442,19.974-29.54772,17.04512-.73874-19.05053-.73874-19.05053,14.58264-33.954,14.27483-41.89177,22.08942-45.37466,22.08942-45.37466L895.432,337.06274s5.85768-12.9466,13.73384-14.84195,14.411,2.621,14.411,2.621L910.397,353.97167l-13.90546,51.417,2.32629,18.989s16.17018-34.01561,26.7905-47.14689,33.26379-44.21805,33.26379-44.21805,5.28646-17.83738,1.2957-23.16791c-3.99136-5.34632,16.64671-6.14663,25.4463,15.77118,4.66382,11.6164,12.23581,28.93924,16.97922,45.068,4.22682,14.28851,6.22343,27.646,2.03588,35.28111C998.229,417.629,982.1292,432.96018,973.92139,450.08405Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#575a89"
        />
        <path
            d="M1004.62926,405.96511c-6.40029,11.66388-22.50006,26.99507-30.70787,44.11894-2.14378-7.72349-4.75909-15.71477-4.75909-15.71477s-10.03082-53.66868,19.64-67.53871a19.85762,19.85762,0,0,1,13.79106,3.85343C1006.8202,384.97251,1008.81681,398.33,1004.62926,405.96511Z"
            transform="translate(-118.90347 -55.71621)"
            opacity={0.1}
        />
        <path
            d="M886.8432,402.583l-25.15446,7.33517-34.926,1.35436-3.593,30.348,57.39783,4.134S894.904,405.45026,886.8432,402.583Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#575a89"
        />
        <path
            d="M927.861,504.62256s-33.65163,5.58312-25.971,15.7098,37.49194-.51979,37.49194-.51979Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#ffb9b9"
        />
        <path
            d="M969.04351,471.89623c.76057,14.69328,6.17341,31.68645,8.11534,44.04442-10.72016,10.14608-20.91438,19.09523-24.75621,19.24421-7.93772.30781-20.69963-.78724-25.4007.985s-7.4583-28.32954-7.4583-28.32954,2.80572-9.64839,15.32139-14.90352C943.31283,489.3816,959.506,479.73877,969.04351,471.89623Z"
            transform="translate(-118.90347 -55.71621)"
            opacity={0.1}
        />
        <path
            d="M988.43294,357.3053s26.86513-4.22164,25.41378,40.35272,2.83185,73.027-7.97315,81.39567-45.90258,46.2981-53.8403,46.60591-20.69964-.78724-25.40071.985-7.45829-28.32954-7.45829-28.32954,2.80572-9.64839,15.32138-14.90352,42.00182-23.88779,41.75558-30.238-7.4583-28.32954-7.4583-28.32954S958.7621,371.17533,988.43294,357.3053Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#575a89"
        />
        <circle cx={803.44211} cy={237.37472} r={34.95222} fill="#ffb9b9" />
        <path
            d="M898.81137,242.99424a6.74905,6.74905,0,0,1-2.93882,1.47478,2.09716,2.09716,0,0,1-2.43029-1.68345c-.79968,1.65841-1.70192,3.42592-3.33461,4.27688s-4.15732.13741-4.39266-1.68863a7.706,7.706,0,0,1-1.77364,3.74391c-1.013.96585-2.85,1.20962-3.73439.12483.715,3.90745.27369,7.92586.49823,11.89184s1.2821,8.17049,4.27847,10.7784c4.36987,3.80333,10.94108,2.86466,16.68929,2.1441a5.06564,5.06564,0,0,1,2.61539.14307c2.15231.917,2.05467,3.9131,2.08369,6.25243a17.37276,17.37276,0,0,0,15.21221,16.49749,8.70857,8.70857,0,0,0,6.47244-1.57965c1.63206-1.38335,2.46572-3.63805,4.33585-4.67723,3.04207-1.6904,6.73153.77486,8.95568,3.45158s4.4162,5.96933,7.86172,6.45931c4.55691.648,8.02766-3.996,9.73047-8.27216A48.223,48.223,0,0,0,957.982,254.862C948.46709,234.12089,915.96382,230.84028,898.81137,242.99424Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#2f2e41"
        />
        <circle cx={594.94496} cy={144.069} r={72} fill="#64d1fb" />
        <path
            d="M635.51737,123.82177c16.589,17.016,42.07228,19.07693,42.07228,19.07693s-1.41283-25.5274-18.00184-42.54338-42.07228-19.07693-42.07228-19.07693S618.92836,106.80579,635.51737,123.82177Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#3f3d56"
        />
        <path
            d="M641.08212,115.076c22.94429,6.18857,37.1672,27.43365,37.1672,27.43365s-22.97713,11.21142-45.92142,5.02285-37.1672-27.43365-37.1672-27.43365S618.13783,108.88738,641.08212,115.076Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#64d1fb"
        />
        <path
            d="M663.60483,98.85189c.15213,23.76374,17.0833,42.92047,17.0833,42.92047s16.68451-19.37193,16.53238-43.13568-17.0833-42.92047-17.0833-42.92047S663.45269,75.08814,663.60483,98.85189Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#3f3d56"
        />
        <path
            d="M673.6791,96.40961c12.23949,20.36992,7.75414,45.53986,7.75414,45.53986s-24.32949-7.85622-36.569-28.22614-7.75414-45.53987-7.75414-45.53987S661.43961,76.03969,673.6791,96.40961Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#64d1fb"
        />
        <circle cx={588.4296} cy={138.54178} r={37} fill="#f2f2f2" />
        <circle cx={571.11421} cy={149.98981} r={12} fill="#3f3d56" />
        <circle cx={208} cy={710.91192} r={72} fill="#64d1fb" />
        <path
            d="M295.46712,662.13912c6.20562,22.93969,27.46126,37.14681,27.46126,37.14681s11.19434-22.98545,4.98872-45.92514S300.45585,616.214,300.45585,616.214,289.26151,639.19944,295.46712,662.13912Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#3f3d56"
        />
        <path
            d="M304.58584,657.20914c17.0283,16.57636,19.10818,42.05809,19.10818,42.05809s-25.52844-1.39386-42.55675-17.97023-19.10818-42.05809-19.10818-42.05809S287.55754,640.63277,304.58584,657.20914Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#64d1fb"
        />
        <path
            d="M332.15785,654.00333c-11.43688,20.83115-5.97465,45.80731-5.97465,45.80731s24.00506-8.79781,35.44194-29.629,5.97464-45.8073,5.97464-45.8073S343.59472,633.17218,332.15785,654.00333Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#3f3d56"
        />
        <path
            d="M342.14655,656.77488c.7735,23.75164-15.39874,43.55324-15.39874,43.55324S309.32149,681.62069,308.548,657.869s15.39875-43.55323,15.39875-43.55323S341.37306,633.02323,342.14655,656.77488Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#64d1fb"
        />
        <circle cx={205} cy={702.91192} r={37} fill="#f2f2f2" />
        <circle cx={217} cy={684.91192} r={12} fill="#3f3d56" />
        <rect x={10} y={781.91192} width={375} height={2} fill="#2f2e41" />
        <rect x={563} y={785.91192} width={375} height={2} fill="#2f2e41" />
        <path
            d="M214.79214,839.08712l-1.77734-.918c23.082-44.624,10.73926-102.57519-3.67871-143.332a439.29179,439.29179,0,0,0-37.27783-78.6748l1.69043-1.06836a441.53655,441.53655,0,0,1,37.47217,79.07519C225.77164,735.299,238.20132,793.82833,214.79214,839.08712Z"
            transform="translate(-118.90347 -55.71621)"
            fill="#2f2e41"
        />
        <rect
            x={190.31331}
            y={650.12846}
            width={72.18033}
            height={1.99936}
            transform="matrix(0.17975, -0.98371, 0.98371, 0.17975, -573.71826, 701.09098)"
            fill="#2f2e41"
        />
        <rect
            x={177.40311}
            y={663.45243}
            width={2.00072}
            height={98.35141}
            transform="translate(-556.56632 256.75078) rotate(-42.93968)"
            fill="#2f2e41"
        />
        <rect
            x={220.89916}
            y={778.12798}
            width={58.00862}
            height={2.00031}
            transform="translate(-646.82753 469.48924) rotate(-54.13715)"
            fill="#2f2e41"
        />
        <circle cx={119} cy={530.91192} r={10} fill="#64d1fb" />
        <circle cx={10} cy={607.91192} r={10} fill="#3f3d56" />
        <circle cx={54} cy={617.91192} r={10} fill="#3f3d56" />
        <circle cx={34} cy={656.91192} r={10} fill="#64d1fb" />
        <circle cx={159} cy={681.91192} r={10} fill="#3f3d56" />
        <circle cx={126} cy={692.91192} r={10} fill="#3f3d56" />
        <circle cx={149} cy={724.91192} r={10} fill="#3f3d56" />
        <circle cx={44} cy={542.91192} r={10} fill="#3f3d56" />
        <rect x={204.59653} y={179.23975} width={482} height={289} fill="#3f3d56" />
        <circle cx={223.59653} cy={190.23975} r={5} fill="#64d1fb" />
        <circle cx={241.59653} cy={190.23975} r={5} fill="#64d1fb" />
        <circle cx={259.59653} cy={190.23975} r={5} fill="#64d1fb" />
        <rect
            x={204.99985}
            y={201.42188}
            width={481.59668}
            height={2}
            fill="#2f2e41"
        />
        <polygon
            points="485.597 260.24 485.597 350.24 354.767 350.24 330.597 379.24 333.357 350.24 321.597 350.24 321.597 260.24 485.597 260.24"
            fill="#64d1fb"
        />
        <rect x={426.59653} y={291.23975} width={59} height={59} opacity={0.1} />
        <polygon
            points="439.597 304.24 603.597 304.24 603.597 394.24 591.835 394.24 594.597 423.24 570.43 394.24 439.597 394.24 439.597 304.24"
            fill="#64d1fb"
        />
        <ellipse cx={331.59653} cy={390.23975} rx={36} ry={3.5122} opacity={0.1} />
        <ellipse cx={594.59653} cy={437.23975} rx={41} ry={4} opacity={0.1} />
    </svg>
);
export default CommunicationImg;
