import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import './StoreLocator.css'
import storepng from "../Assets/Images/storeimg.png"
import restored1 from "../Assets/restored/restored1.jpeg";
import restored2 from "../Assets/restored/restored2.jpeg";
import restored3 from "../Assets/restored/restored3.jpeg";
import restored4 from "../Assets/restored/restored4.jpeg";
import restored5 from "../Assets/restored/restored5.jpeg";
import restored6 from "../Assets/restored/restored6.jpeg";
import restored7 from "../Assets/restored/restored7.jpeg";
import restored8 from "../Assets/restored/restored8.jpeg";
import restored9 from "../Assets/restored/restored9.jpeg";
import restored10 from "../Assets/restored/restored10.jpeg";
import restored11 from "../Assets/restored/restored11.jpeg";
import restored12 from "../Assets/restored/restored12.jpeg";




import { Carousel } from 'antd';


export default function StoreLocator() {
    console.log(window.innerWidth);

    const handleAddressClick = (long, lat, label) => {
        const latitude = lat;
        const longitude = long;
        const locationLabel = label;

        const mapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}&label=${locationLabel}`;


        window.open(mapsUrl);
    };
    const stores = [{
        name: "Faridabad Store",
        address: "DJT RETAILERS PRIVATE LIMITED PACIFIC MALL, NIT BUS TERMINAL, NEAR BK CHOWK, FARIDABAD, HARYANA, 121001",
        long: "77.2957909",
        lat: "28.3866855",
        contactus: "18001236477",
        whatsappus: "9899054616",
        emailus: "Help@deerika.in",
        imgpath: [
            restored2, restored4, restored6, restored8
        ],
    },
    {
        name: "DLF Phase 3 Gurugram",
        address: "U 14A/1,2,3 & 4, Basement & Ground, DLF Phase 3, Gurugram, Haryana, 122002",
        long: "77.09579",
        lat: "28.49452",
        contactus: "18001236477",
        whatsappus: "9899054616",
        emailus: "Help@deerika.in",
        imgpath: [
            restored1, restored5, restored7, restored3
        ],
    },
    {
        name: "Mall 51, Gurugram",
        address: "Mall 51 1st Floor Mall Fifty One Plot No. M1, M2, Sector 51, Gurugram, Haryana, 122018",
        long: "77.06876",
        lat: "28.43486",
        contactus: "18001236477",
        whatsappus: "9899054616",
        emailus: "Help@deerika.in",
        imgpath: [
            restored11, restored3, restored1, restored5
        ],
    },
    {
        name: "Pacific Mall , Jasola Apollo - Delhi",
        address: "1 CPD- 48, SARITA VIHAR, MATHURA ROAD, PACIFIC MALL, JASOLA APOLLO METRO STATION, New Delhi, 110044",
        long: "77.289841",
        lat: "28.527519",
        contactus: "18001236477",
        whatsappus: "9899054616",
        emailus: "Help@deerika.in",
        imgpath: [
            restored12, restored4, restored10, restored9, restored8
        ],
    },
    {
        name: "Pacific Mall Ghaziabad",
        address: "Lower Ground Floor, Pacific Mall, Plot No. 1A, Dr. Burman Road Sahibabad Industrial Area, Site-IV",
        long: "77.33867",
        lat: "28.66205",
        contactus: "18001236477",
        whatsappus: "9899054616",
        emailus: "Help@deerika.in",
        imgpath: [
            restored12, restored5, restored6, restored7, restored8
        ],
    },
    ]
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navbar />

            <div className='locatordiv'>

                <div className='storediv1'>
                    <h1 className='storediv1h1'
                    >YOUR FAVOURITE STORE IS NOW OFFLINE!</h1>
                </div>
            </div>
            {stores.map((product) =>
                <>
                    <div className='mallsdiv'>
                        <div className='mallsdiv1'>
                            <h1 className='mallsdiv1h1'>{product?.name}</h1>
                        </div>
                        <div className={window.innerWidth < 500 ? "" : 'mallsdiv2'}>
                            <div className={window.innerWidth < 500 ? "" : 'wrapper'}>
                                <Carousel autoplay={true}
                                    dots={false}
                                    autoplaySpeed={2000}
                                    infinite={true}>
                                    {product?.imgpath.map((path) =>
                                        <div >
                                            <img src={path} height={window.innerWidth < 500 ? "30%" : "550px"} width={window.innerWidth < 500 ? "70%" : "550px"}
                                                style={{ borderRadius: "40px", marginLeft: window.innerWidth < 500 ? "15%" : ((window.innerWidth > 1199 && window.innerWidth < 1575) ? "22%" : "30%") }} alt="no loded"></img>
                                            {/* marginLeft: window.innerWidth < 500 ? "15%" : "35%" */}
                                        </div>


                                    )}
                                </Carousel>

                            </div>

                            <div className='addressdiv'>
                                <h1 className='textdiv'>ADDRESS</h1>
                                <p className='textdiv' style={{ width: window.innerWidth < 500 ? "100%" : "100%" }}><a onClick={
                                    () => handleAddressClick(product?.long, product?.lat, product?.name)}>{product?.address}</a></p>
                                <h2 className='textdiv'>Contact Us</h2>
                                <p className='textdiv'>{product?.contactus}</p>
                                <h2 className='textdiv'>What’sApp Us</h2>
                                <p className='textdiv'>
                                    <a href={`https://wa.me/${product?.whatsappus}`}>
                                        {product?.whatsappus}</a></p>
                                <h2 className='textdiv'>Email Us </h2>
                                <p className='textdiv'>
                                    <a href="mailto: help@deerika.in" target="_blank">
                                        {product?.emailus}
                                    </a>
                                </p>

                            </div>
                        </div>

                    </div>
                </>
            )
            }


            <Footer />

        </>
    )
}
