import React from 'react'
import appstore from "../Assets/Images/appstore.png";

import playstore from "../Assets/Images/playstore.png";

export function GetApp1() {
    return (
        <div>
            <img src={playstore} width={136} height={48}></img>
        </div>
    )
}
export function GetApp2() {
    return (
        <div>
            <img src={appstore} width={136} height={48}></img>
        </div>
    )
}

