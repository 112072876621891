import * as React from "react";
const FunImg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width={256}
        height={223}
        viewBox="0 0 732.64277 638.92105"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <path
            d="M355.19705,352.95965a33.50275,33.50275,0,0,1-5.15926.12031,1.12527,1.12527,0,0,0-1.23851.97666l-3.29091,24.68526a10.02142,10.02142,0,0,1-2.12316,4.89034,10.65824,10.65824,0,0,0-2.39918,8.14585v41.93238s-.21231,5.591,4.08355,5.591a62.10276,62.10276,0,0,0,15.48491,0s3.43951-.6511,3.43951-5.81038V392.2027a10.09919,10.09919,0,0,0-2.12316-8.20955,10.276,10.276,0,0,1-2.05945-4.862l-3.4183-25.20189A1.12526,1.12526,0,0,0,355.19705,352.95965Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#fff"
        />
        <path
            d="M352.797,440.49242a62.674,62.674,0,0,1-7.773-.48345,4.17988,4.17988,0,0,1-3.09921-1.26857c-1.74822-1.81561-1.651-4.92536-1.64594-5.057l-.00051-41.86223a11.40411,11.40411,0,0,1,2.56375-8.64261,9.30817,9.30817,0,0,0,1.96541-4.53555l3.29031-24.68a1.84294,1.84294,0,0,1,2.01689-1.5865,32.6571,32.6571,0,0,0,5.01158-.12095l.01969-.00172a1.8326,1.8326,0,0,1,1.94831,1.57923l3.41851,25.20318a9.5778,9.5778,0,0,0,1.91686,4.52242,10.77949,10.77949,0,0,1,2.27209,8.70689V433.4909c0,5.67973-3.97539,6.49837-4.01565,6.506A63.14407,63.14407,0,0,1,352.797,440.49242Zm-2.85541-86.70877a.42442.42442,0,0,0-.44026.36215l-3.29151,24.68967a10.73614,10.73614,0,0,1-2.27383,5.2412,9.99118,9.99118,0,0,0-2.24756,7.6142l.00535.08709v41.93238c-.00138.05218-.07654,2.67433,1.25389,4.05176a2.79106,2.79106,0,0,0,2.122.83178l.08829.00553a61.46578,61.46578,0,0,0,15.30824,0c.06721-.01659,2.82016-.64448,2.82016-5.10851l.01141-41.41507a9.37629,9.37629,0,0,0-1.97439-7.63424,11.01282,11.01282,0,0,1-2.21249-5.21114l-3.419-25.20629a.41994.41994,0,0,0-.43628-.359,34.36271,34.36271,0,0,1-5.25572.12164A.55569.55569,0,0,0,349.94156,353.78365Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#3f3d56"
        />
        <path
            d="M116.32377,261.98877h5.21588a7.82029,7.82029,0,0,1,7.82029,7.82029v19.62507a7.8203,7.8203,0,0,1-7.8203,7.8203h-5.21587a7.8203,7.8203,0,0,1-7.8203-7.8203V269.80907A7.8203,7.8203,0,0,1,116.32377,261.98877Z"
            fill="#63d1ff"
        />
        <polygon
            points="20.432 622.437 37.437 622.437 45.527 556.846 20.429 556.847 20.432 622.437"
            fill="#9f616a"
        />
        <path
            d="M249.77314,747.42485l33.489-.00136h.00136A21.34295,21.34295,0,0,1,304.6053,768.765v.69352l-54.83115.002Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <path
            d="M243.42009,518.702s-7.36985,8.33074,4.83348,40.805l-2.14951,178.06312,39.54717-.77152,8.69291-86.85779L310.1713,523.19059Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <path
            d="M323.73484,543.3204l-.433-.06611c-50.59181-7.71594-88.76927-15.11294-89.149-15.18678l-.47425-.092,13.26225-32.257c-.10477-3.37068-2.97558-98.02325,2.52008-125.2179,5.32015-26.326,30.39753-30.64031,33.2552-31.04623l2.86946-9.50739,36.25147,18.86363,6.32248,21.76,1.28425,59.63878Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#3f3d56"
        />
        <path
            d="M345.56864,420.57049a11.83043,11.83043,0,0,0-8.69092,3.8009H275.945l-22.31936,4.7509,3.66448,28.26389,79.39621-17.07512a11.87089,11.87089,0,1,0,8.88228-19.74057Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#9f616a"
        />
        <circle
            cx={320.3296}
            cy={305.17684}
            r={29.36627}
            transform="translate(-334.77698 309.33118) rotate(-61.33684)"
            fill="#9f616a"
        />
        <path
            d="M292.71018,281.7l-1.42366-2.8654,7.16351-3.55915s7.9025-12.85821,22.20235-9.24477,20.73358,5.776,20.73358,5.776l7.14539,3.59532-3.58624,3.56819,6.43357,2.16264-4.29807,2.13548,5.00092,2.87444-2.6921,14.73534s-4.47141-11.17619-13.06756-6.90524-24.31981-2.20782-24.31981-2.20782l-13.6604,26.43719s-2.81814-9.26755-7.84272-6.79639C290.49886,311.40586,277.66236,290.96281,292.71018,281.7Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <path
            d="M257.68022,462.68439,250.712,441.957l-1.68553-69.0936,2.969-9.80314a27.38884,27.38884,0,1,1,51.7277,17.89639l-15.91357,40.779.80894,1.56994,34.47683-.27912L335.342,445.0706Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#3f3d56"
        />
        <path
            d="M602.627,454.16756a10.61126,10.61126,0,0,0,1.6953-16.18255l16.99418-33.6624-19.465,2.2578-13.15376,31.62024A10.66877,10.66877,0,0,0,602.627,454.16756Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#ffb7b7"
        />
        <polygon
            points="505.24 562.075 489.924 562.074 482.638 502.999 505.242 503.001 505.24 562.075"
            fill="#ffb7b7"
        />
        <path
            d="M742.82423,707.46023l-49.38381-.00183v-.62463A19.22259,19.22259,0,0,1,712.662,687.61252h.00122l30.162.00122Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <polygon
            points="421.54 562.075 406.224 562.074 398.938 502.999 421.543 503.001 421.54 562.075"
            fill="#ffb7b7"
        />
        <path
            d="M655.37672,707.46023l-49.38381-.00183v-.62463a19.22259,19.22259,0,0,1,19.22155-19.22125h.00122l30.16195.00122Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <polygon
            points="420.285 312.572 405.294 347.108 392.928 545.614 424.159 544.365 432.689 449.736 459.002 365.454 474.129 459.415 480.376 541.866 506.218 542.985 516.516 312.572 420.285 312.572"
            fill="#2f2e41"
        />
        <path
            d="M728.899,272.694l-43.44888,1.08685-25.50892,13.09525-1.3999,96.6139-15.90944,66.70389s99.40388,6.84133,113.14563-3.15267l-16.24025-76.20426,21.78179-82.355Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#63d1ff"
        />
        <path
            d="M666.65057,288.47751l-4.04459-2.73159s-16.757,5.02234-21.7098,27.36637c0,0-3.79,11.21018-5.453,18.91745-1.90739,8.8402-44.02617,90.04135-44.02617,90.04135l25.21707,7.23431,46.232-74.76592Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#63d1ff"
        />
        <path
            d="M760.63836,466.55646a10.61127,10.61127,0,0,1,2.83289-16.0226l-7.05258-37.04349,18.08768,7.53789,3.92422,34.02148a10.66878,10.66878,0,0,1-17.79221,11.50672Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#ffb7b7"
        />
        <path
            d="M754.78155,289.63567l4.641-1.51034s14.72235,9.44849,13.32155,32.29195c0,0,.55178,11.82066.0249,19.68766-.60433,9.02342,7.4955,98.69077,7.4955,98.69077H754.03028L742.787,357.59445Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#63d1ff"
        />
        <path
            d="M739.56476,232.16033a30.11942,30.11942,0,1,1-30.11944-30.11941h0a30.04447,30.04447,0,0,1,30.1194,29.96935Q739.56493,232.0853,739.56476,232.16033Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#ffb7b7"
        />
        <path
            d="M679.426,191.7045c3.52832-7.5871,10.35635-6.08706,16.32792-3.51525,7.56208-1.67738,14.75411-6.69923,22.90181-3.72933,8.02585,11.67312,34.97217,8.23871,29.23939,27.24263-.00749,4.55375,8.56874,1.90317,7.0715,9.36116,4.54315,14.35282-16.39873,41.53492-28.421,35.81082,2.97327-5.44922,9.76538-17.82623-.53635-19.05385-22.16059,20.61963-2.28641-39.26444-30.13067-21.84919C686.65921,224.40872,674.03758,200.14386,679.426,191.7045Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <path
            d="M765.91087,469.55994a10.00931,10.00931,0,0,0,11.97985-9.59409L853.518,388.78344l-17.0164-13.574-69.11686,74.41809a10.06355,10.06355,0,0,0-1.47386,19.93241Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#9f616a"
        />
        <path
            d="M842.80154,400.28953,828.08838,383.0467a4.488,4.488,0,0,1,1.03243-6.71709l19.15464-11.991a12.46411,12.46411,0,0,1,16.12523,19.01023l-14.80328,16.978a4.488,4.488,0,0,1-6.79586-.03728Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#cbcbcb"
        />
        <polygon
            points="569.558 620.997 551.067 620.996 542.27 549.675 569.561 549.676 569.558 620.997"
            fill="#9f616a"
        />
        <path
            d="M807.95189,769.46052l-59.622-.00221v-.75412A23.20776,23.20776,0,0,1,771.53646,745.498h.00147l36.41507.00147Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <polygon
            points="717.365 620.997 698.875 620.996 690.078 549.675 717.369 549.676 717.365 620.997"
            fill="#9f616a"
        />
        <path
            d="M955.75964,769.46052l-59.622-.00221v-.75412A23.20778,23.20778,0,0,1,919.3442,745.498h.00148l36.41506.00147Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <path
            d="M901.30653,438.0961l18.63858-68.863-24.17978-13.59139H862.01439l-13.12731,14.04444s-21.932,15.25939-16.6236,32.16627l8.56367,38.50893-13.09738,61.16123-65.99064,91.51533L746.118,682.93028l61.49188,16.12041,66.49439-130.93034L906.344,713.09512l59.97741-3.01648L945.65408,534.29672s-5.89164-53.54942-36.77341-86.98486Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <path
            d="M939.79877,499.02537a10.00932,10.00932,0,0,0-1.28244-15.2944l-14.46166-97.5642-20.757,6.55425,20.77824,93.96478a10.06355,10.06355,0,0,0,15.72286,12.33957Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#9f616a"
        />
        <path
            d="M927.62875,401.4788,905.111,404.07517a4.488,4.488,0,0,1-4.99972-4.603l.72936-22.58654a12.46412,12.46412,0,0,1,24.77233-2.78279l5.83678,21.75592a4.488,4.488,0,0,1-3.821,5.62009Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#cbcbcb"
        />
        <path
            d="M841.14625,336.05834V307.04351a38.87987,38.87987,0,1,1,77.75974,0v29.01483a5.22857,5.22857,0,0,1-5.22267,5.22267H846.36891A5.22856,5.22856,0,0,1,841.14625,336.05834Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <circle
            cx={872.0367}
            cy={312.10021}
            r={28.50536}
            transform="translate(-276.51631 325.98852) rotate(-28.66322)"
            fill="#9f616a"
        />
        <path
            d="M831.94821,309.3647A30.7905,30.7905,0,0,1,862.70392,278.609h5.80325a30.79026,30.79026,0,0,1,30.75544,30.75572v.58029H886.999l-4.18278-11.71247L881.9798,309.945h-6.33793l-2.11037-5.9095-.42219,5.9095h-41.1611Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <path
            d="M869.23138,344.54377a5.14537,5.14537,0,0,1-.40887-5.46295c6.1495-11.6966,14.75959-33.30925,3.331-46.63794l-.82143-.95771h33.17834v49.81256l-30.14,5.31788a5.3353,5.3353,0,0,1-.92428.0816A5.20168,5.20168,0,0,1,869.23138,344.54377Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <path
            d="M564.2779,371.06852a11.47832,11.47832,0,0,0-1.154,1.39088l-54.05933-1.32268L502.758,359.61258l-18.636,7.24458,9.18192,21.48621a8.723,8.723,0,0,0,9.06747,5.23226l61.077-7.37823a11.44584,11.44584,0,1,0,.82955-15.12888Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#ffb7b7"
        />
        <polygon
            points="154.374 568.836 172.149 568.836 180.605 500.275 154.371 500.276 154.374 568.836"
            fill="#ffb7b7"
        />
        <path
            d="M383.51868,693.57253l35.00552-.00142h.00142A22.30946,22.30946,0,0,1,440.83388,715.879v.72493l-57.31414.00213Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <polygon
            points="315.778 568.441 332.124 561.458 312.967 495.086 288.843 505.393 315.778 568.441"
            fill="#ffb7b7"
        />
        <path
            d="M543.007,695.42455l32.191-13.75238.0013-.00056A22.30946,22.30946,0,0,1,604.47739,693.423l.28477.66666-52.706,22.51643Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <polygon
            points="240.773 255.381 242.058 276.21 204.487 280.231 165.173 284.438 176.744 252.81 240.773 255.381"
            fill="#ffb7b7"
        />
        <path
            d="M475.77146,393.39429c-.42593-.16718-42.86293-16.67463-68.92958-3.17691l-.641.33223-5.20008-25.59573-2.96314-55.02963,19.281-9.11064,5.30869-11.89143,36.15932-2.13977,7.43062,10.19038,25.29891,8.29126L476.396,393.64132Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#e4e4e4"
        />
        <path
            d="M477.10036,343.72286l4.47253-35.14241,10.184-3.09971.1717.06336c.23506.08625,5.76976,2.20472,7.28473,9.8672,1.45429,7.35474,12.40655,53.12305,12.517,53.58465l.10968.45788-25.73309,10.29579Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#e4e4e4"
        />
        <path
            d="M536.21761,686.9309l34.25472-7.02661-54.749-151.07208-18.73763-51.52846S485.10124,435.98844,479.73181,420l-3.24037-21.45282-76.70714,8.49049s-21.42411,31.46334-14.91562,82.7467L381.925,682.53927l34.25472,1.75665,28.692-194.40283,50.35736,106.57023s15.87209,45.47732,27.22811,55.33454Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <path
            d="M430.54561,414.1922a11.47765,11.47765,0,0,0-1.80457.09942l-35.78547-40.54086,4.16272-12.45995-17.98316-8.74-9.51292,21.34173a8.72306,8.72306,0,0,0,2.32826,10.20661l46.93716,39.771a11.44583,11.44583,0,1,0,11.658-9.67794Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#ffb7b7"
        />
        <path
            d="M375.8954,358.55694,389.88534,318.708c1.57222-4.69748,3.54241-7.55121,5.856-8.4824a3.61333,3.61333,0,0,1,2.90324-.02715l6.27315,7.92549,5.8222,24.4585L395.81216,377.6961Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#e4e4e4"
        />
        <path
            d="M468.49581,278.53619h-61.416a4.77041,4.77041,0,0,1-4.765-4.765V247.29874a35.473,35.473,0,0,1,70.94606,0v26.47241A4.77041,4.77041,0,0,1,468.49581,278.53619Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <circle cx={211.39854} cy={121.37287} r={26.00758} fill="#ffb7b7" />
        <path
            d="M481.65291,249.946H444.09853l-.38519-5.39168-1.92546,5.39168h-5.78256l-.76315-10.68617L431.4259,249.946h-11.189v-.52945a28.09227,28.09227,0,0,1,28.0605-28.06075h5.29474a28.09249,28.09249,0,0,1,28.06076,28.06075Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <path
            d="M443.79118,283.4778a4.86705,4.86705,0,0,1-.84329-.07446l-27.499-4.85189V233.10369H445.72l-.74945.8738c-10.42713,12.16076-2.5715,31.87961,3.03915,42.5513a4.6945,4.6945,0,0,1-.373,4.98426A4.74591,4.74591,0,0,1,443.79118,283.4778Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#2f2e41"
        />
        <path
            d="M773.19705,392.72009a33.504,33.504,0,0,1-5.15926.12031,1.12527,1.12527,0,0,0-1.23851.97665l-3.29091,24.68526a10.02141,10.02141,0,0,1-2.12316,4.89035,10.65824,10.65824,0,0,0-2.39918,8.14585v41.93238s-.21231,5.591,4.08355,5.591a62.10276,62.10276,0,0,0,15.48491,0s3.43951-.6511,3.43951-5.81039V431.96314a10.09918,10.09918,0,0,0-2.12316-8.20955,10.276,10.276,0,0,1-2.05945-4.862l-3.4183-25.20189A1.12526,1.12526,0,0,0,773.19705,392.72009Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#fff"
        />
        <path
            d="M770.797,480.25286a62.674,62.674,0,0,1-7.773-.48345,4.17992,4.17992,0,0,1-3.09921-1.26857c-1.74822-1.81561-1.65095-4.92536-1.64594-5.057l-.00051-41.86223a11.4041,11.4041,0,0,1,2.56375-8.64261,9.30817,9.30817,0,0,0,1.96541-4.53556l3.29031-24.68a1.84294,1.84294,0,0,1,2.01689-1.58649,32.65836,32.65836,0,0,0,5.01158-.12095l.01969-.00173a1.83261,1.83261,0,0,1,1.94831,1.57924l3.41851,25.20318a9.5778,9.5778,0,0,0,1.91686,4.52242,10.77949,10.77949,0,0,1,2.27209,8.70689v41.22535c0,5.67973-3.97539,6.49837-4.01565,6.506A63.14218,63.14218,0,0,1,770.797,480.25286Zm-2.85541-86.70877a.42442.42442,0,0,0-.44026.36215l-3.29151,24.68966a10.73613,10.73613,0,0,1-2.27383,5.24121,9.99118,9.99118,0,0,0-2.24756,7.6142l.00535.08708v41.93238c-.00138.05219-.07654,2.67434,1.25389,4.05177a2.79106,2.79106,0,0,0,2.12195.83178l.08829.00552a61.46482,61.46482,0,0,0,15.30824,0c.06721-.01658,2.82016-.64448,2.82016-5.1085l.01141-41.41507a9.37632,9.37632,0,0,0-1.97439-7.63425,11.01274,11.01274,0,0,1-2.21249-5.21113l-3.419-25.20629a.41994.41994,0,0,0-.43628-.359,34.364,34.364,0,0,1-5.25572.12164A.55569.55569,0,0,0,767.94156,393.54409Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#3f3d56"
        />
        <path
            d="M534.32377,301.74921h5.21588a7.82029,7.82029,0,0,1,7.82029,7.82029v19.62507a7.8203,7.8203,0,0,1-7.8203,7.8203h-5.21588a7.82029,7.82029,0,0,1-7.82029-7.82029V309.56951A7.8203,7.8203,0,0,1,534.32377,301.74921Z"
            fill="#63d1ff"
        />
        <path
            d="M596.19705,358.72009a33.504,33.504,0,0,1-5.15926.12031,1.12527,1.12527,0,0,0-1.23851.97665l-3.29091,24.68526a10.02141,10.02141,0,0,1-2.12316,4.89035,10.65824,10.65824,0,0,0-2.39918,8.14585v41.93238s-.21231,5.591,4.08355,5.591a62.10276,62.10276,0,0,0,15.48491,0s3.43951-.6511,3.43951-5.81039V397.96314a10.09918,10.09918,0,0,0-2.12316-8.20955,10.276,10.276,0,0,1-2.05945-4.862l-3.4183-25.20189A1.12526,1.12526,0,0,0,596.19705,358.72009Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#fff"
        />
        <path
            d="M593.797,446.25286a62.674,62.674,0,0,1-7.773-.48345,4.17992,4.17992,0,0,1-3.09921-1.26857c-1.74822-1.81561-1.65095-4.92536-1.64594-5.057l-.00051-41.86223a11.4041,11.4041,0,0,1,2.56375-8.64261,9.30817,9.30817,0,0,0,1.96541-4.53556l3.29031-24.68a1.84294,1.84294,0,0,1,2.01689-1.58649,32.65836,32.65836,0,0,0,5.01158-.12095l.01969-.00173a1.83261,1.83261,0,0,1,1.94831,1.57924l3.41851,25.20318a9.5778,9.5778,0,0,0,1.91686,4.52242,10.77949,10.77949,0,0,1,2.27209,8.70689v41.22535c0,5.67973-3.97539,6.49837-4.01565,6.506A63.14218,63.14218,0,0,1,593.797,446.25286Zm-2.85541-86.70877a.42442.42442,0,0,0-.44026.36215l-3.29151,24.68966a10.73613,10.73613,0,0,1-2.27383,5.24121,9.99118,9.99118,0,0,0-2.24756,7.6142l.00535.08708v41.93238c-.00138.05219-.07654,2.67434,1.25389,4.05177a2.79106,2.79106,0,0,0,2.12195.83178l.08829.00552a61.46482,61.46482,0,0,0,15.30824,0c.06721-.01658,2.82016-.64448,2.82016-5.1085l.01141-41.41507a9.37632,9.37632,0,0,0-1.97439-7.63425,11.01274,11.01274,0,0,1-2.21249-5.21113l-3.419-25.20629a.41994.41994,0,0,0-.43628-.359,34.364,34.364,0,0,1-5.25572.12164A.55569.55569,0,0,0,590.94156,359.54409Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#3f3d56"
        />
        <path
            d="M357.32377,267.74921h5.21588a7.82029,7.82029,0,0,1,7.82029,7.82029v19.62507a7.8203,7.8203,0,0,1-7.8203,7.8203h-5.21588a7.82029,7.82029,0,0,1-7.82029-7.82029V275.56951A7.8203,7.8203,0,0,1,357.32377,267.74921Z"
            fill="#63d1ff"
        />
        <path
            d="M566.19705,322.72009a33.504,33.504,0,0,1-5.15926.12031,1.12527,1.12527,0,0,0-1.23851.97665l-3.29091,24.68526a10.02141,10.02141,0,0,1-2.12316,4.89035,10.65824,10.65824,0,0,0-2.39918,8.14585v41.93238s-.21231,5.591,4.08355,5.591a62.10276,62.10276,0,0,0,15.48491,0s3.43951-.6511,3.43951-5.81039V361.96314a10.09918,10.09918,0,0,0-2.12316-8.20955,10.276,10.276,0,0,1-2.05945-4.862l-3.4183-25.20189A1.12526,1.12526,0,0,0,566.19705,322.72009Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#fff"
        />
        <path
            d="M563.797,410.25286a62.674,62.674,0,0,1-7.773-.48345,4.17992,4.17992,0,0,1-3.09921-1.26857c-1.74822-1.81561-1.65095-4.92536-1.64594-5.057l-.00051-41.86223a11.4041,11.4041,0,0,1,2.56375-8.64261,9.30817,9.30817,0,0,0,1.96541-4.53556l3.29031-24.68a1.84294,1.84294,0,0,1,2.01689-1.58649,32.65836,32.65836,0,0,0,5.01158-.12095l.01969-.00173a1.83261,1.83261,0,0,1,1.94831,1.57924l3.41851,25.20318a9.5778,9.5778,0,0,0,1.91686,4.52242,10.77949,10.77949,0,0,1,2.27209,8.70689v41.22535c0,5.67973-3.97539,6.49837-4.01565,6.506A63.14218,63.14218,0,0,1,563.797,410.25286Zm-2.85541-86.70877a.42442.42442,0,0,0-.44026.36215l-3.29151,24.68966a10.73613,10.73613,0,0,1-2.27383,5.24121,9.99118,9.99118,0,0,0-2.24756,7.6142l.00535.08708v41.93238c-.00138.05219-.07654,2.67434,1.25389,4.05177a2.79106,2.79106,0,0,0,2.12195.83178l.08829.00552a61.46482,61.46482,0,0,0,15.30824,0c.06721-.01658,2.82016-.64448,2.82016-5.1085l.01141-41.41507a9.37632,9.37632,0,0,0-1.97439-7.63425,11.01274,11.01274,0,0,1-2.21249-5.21113l-3.419-25.20629a.41994.41994,0,0,0-.43628-.359,34.364,34.364,0,0,1-5.25572.12164A.55569.55569,0,0,0,560.94156,323.54409Z"
            transform="translate(-233.67861 -130.53948)"
            fill="#3f3d56"
        />
        <path
            d="M327.32377,231.74921h5.21588a7.82029,7.82029,0,0,1,7.82029,7.82029v19.62507a7.8203,7.8203,0,0,1-7.8203,7.8203h-5.21588a7.82029,7.82029,0,0,1-7.82029-7.82029V239.56951A7.8203,7.8203,0,0,1,327.32377,231.74921Z"
            fill="#63d1ff"
        />
        <circle cx={56.18747} cy={46} r={25} fill="#f0f0f0" />
        <circle cx={632.18747} cy={25} r={25} fill="#f0f0f0" />
        <circle cx={346.18747} cy={95} r={18} fill="#f0f0f0" />
    </svg>
);
export default FunImg;
