import React from "react";
import Logo from "../Assets/Images/Deerikalogo.png";

const DeleteAc = () => {
    return (
        <>
            <div className="h-[60px] border-b flex items-center px-4 ">
                <img src={Logo} width={150} height={45} alt=""/>
            </div>
            <div
                className=" mt-8 space-y-6"
                style={{ padding: "20px", maxWidth: "600px" }}
            >
                <p className=" text-xl font-semibold">
                    {" "}
                    To Delete Your Deerika account:
                </p>
                <ol style={{ listStyle: 'auto' }} className=" space-y-2 text-gray-700 pl-8" start={"1"} type="1">
                    <li>Open the Deerika app.</li>
                    <li>
                        Tap on Menu &gt; My Account &gt; Select Delete Account &gt; Confirm a reason for deleting your account &gt; Submit by clicking on CLOSE MY ACCOUNT
                    </li>
                </ol>
                <p className=" text-xl font-semibold">Deleting your account will:</p>
                <ol style={{ listStyle: 'auto' }} className="pl-8 space-y-2  text-gray-700 ">
                    <li>Remove your profile and data from all Deerika services.</li>
                    <li>
                        All the data associated with this account (including your profile
                        name, number,cashback/wallet amount, coupon/voucher) will be deleted
                        permanently. This information can&apos;t be recovered once the
                        account is deleted.
                    </li>
                    <li>Retain certain information as required by law.</li>
                </ol>
            </div>
        </>
    );
};

export default DeleteAc;
